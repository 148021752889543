<template>
  <div style="background-color: #fff;width: 100%;">
    <div class="header flexal">
      <router-link to="/">
        <img src="../images/logo.png" alt="" style="width: 165px;margin-right: 24px;" />
      </router-link>
      <h1>欢迎登录</h1>
    </div>
    <div class="cents">
      <div class="box flexspb m-login">
        <img src="/static/img/enter1.png" alt="" style="width: 100%;height: 100%;" />
        <div class="login-form" style="position: absolute;z-index: 1;right: 400px;" v-if="zhuce && !wangji">
          <div class="title oh flexspb">
            <h1 class="fl">免费注册</h1>
            <div class="regist-link fr">
              <!-- <a href="/register">免费注册</a> -->
              <span style="color: #0C6A6A;cursor: pointer;" @click="zhuce = false">登录</span>
            </div>
          </div>
          <div class="u-msg-wrap">
            <!-- <div class="msg msg-warn" style="display:none;">
              <i></i>
              <span>公共场所不建议自动登录，以防帐号丢失</span>
            </div> -->
            <div class="msg msg-err" style="display:none;">
              <i></i>
              <span class="J-errorMsg"></span>
            </div>
          </div>
          <form id="login-form" method="post">
            <p class="co_ye"></p>
            <div class="u-input mb20">
              <!-- <label class="u-label u-name"></label> -->
              <input type="text" class="u-txt J-input-txt" value="" placeholder="手机号" autocomplete="off"
                v-model="LoginUser.phone" maxlength="11" style="width: 284px;" />
            </div>
            <div class="u-input mb15"
              style="border: 1px solid #ccc;display: flex;justify-content: space-between;align-items: center;">
              <!-- <label class="u-label u-pwd"></label> -->
              <input type="text" class="u-txt J-input-txt" placeholder="验证码" name="password" id="password"
                autocomplete="off" v-model="LoginUser.codes" style="width: 130px;border: 0;" />
              <div @click="getCode" style="align-items: center;justify-content: center;display: flex;cursor: pointer;color: #fff;width: 100px;
                            height: 30px;background: #0C6A6A;border-radius: 7px 7px 7px 7px;margin-right: 5px;">
                <div v-if="isClick">发送验证码</div>
                <div v-else>{{ second + 's后重新获取' }}</div>
              </div>
            </div>
            <div class="u-input mb20">
              <!-- <label class="u-label u-name"></label> -->
              <input type="text" class="u-txt J-input-txt" value="" placeholder="用户名" autocomplete="off" name="username"
                id="username" v-model="LoginUser.name" maxlength="11" style="width: 284px;" />
            </div>
            <div class="u-input mb20">
              <!-- <label class="u-label u-name"></label> -->
              <input type="password" class="u-txt J-input-txt" value="" placeholder="密码" autocomplete="off"
                v-model="LoginUser.pass" maxlength="11" style="width: 284px;" />
            </div>
            <div class="u-safe">
              <span class="auto">
                <label>
                  <input type="hidden" name="referurl" id="referurl" value="/Home/User/index.html" />
                  <input type="checkbox" class="u-ckb J-auto-rmb" name="remember"
                    style="visibility:hidden" /><!-- 自动登录 -->
                </label>
              </span>
              <span class="forget" style="cursor: pointer;" @click="wangji = true">忘记密码？</span>
              <br />
              <div class="line liney clearfix">
                <label class="linel">&nbsp;</label>
                <div class="liner">
                  <div class="clearfix checkcon">
                    <p class="fn-fl checktxt">
                      <input type="checkbox" class="iyes fn-fl J_protocal" checked v-model="checked" />
                      <span class="fn-fl">我已阅读并同意</span>
                      <router-link to="/helpInfo?id=3">《服务条款》</router-link>
                      <router-link to="/helpInfo?id=11">《隐私条款》</router-link>
                    </p>
                    <p class="fn-fl errorbox v-txt" id="protocalBox"></p>
                  </div>
                  <a id="submit" class="regbtn J_btn_agree" href="javascript:void(0);"
                    onClick="$('#reg_form2').submit();">同意协议并注册</a>
                  <p class="v-txt" id="err_check_code" v-if="false">
                    <span class="fnred">请勾选</span>我已阅读并同意<a class="itxt" :href="$target + '/服务协议.docx'"
                      target="_blank">《服务条款》</a>
                    <a class="itxt" :href="$target + '/隐私条款.docx'" target="_blank">《隐私条款》</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="u-btn mb20 mt20">
              <a href="javascript:void(0);" @click="zhuces()" class="J-login-submit" name="sbtbutton"
                style="background: #0C6A6A;">立即注册</a>
            </div>
            <div style="color: #0C6A6A;margin: 16px auto 0;text-align: center;cursor: pointer;" @click="zhuce = false ,wangji = false">
              已有账户去登录</div>
          </form>
        </div>
        <div class="login-form" style="position: absolute;z-index: 1;right: 400px;" v-if="wangji">
          <div class="title oh flexspb">
            <h1 class="fl">忘记密码</h1>
          </div>
          <div class="u-msg-wrap">
            <!-- <div class="msg msg-warn" style="display:none;">
              <i></i>
              <span>公共场所不建议自动登录，以防帐号丢失</span>
            </div> -->
            <div class="msg msg-err" style="display:none;">
              <i></i>
              <span class="J-errorMsg"></span>
            </div>
          </div>
          <form id="login-form" method="post">
            <p class="co_ye"></p>
            <div class="u-input mb20">
              <!-- <label class="u-label u-name"></label> -->
              <input type="text" class="u-txt J-input-txt" value="" placeholder="手机号" autocomplete="off"
                v-model="LoginUser.phone" maxlength="11" style="width: 284px;" />
            </div>
            <div class="u-input mb15"
              style="border: 1px solid #ccc;display: flex;justify-content: space-between;align-items: center;">
              <!-- <label class="u-label u-pwd"></label> -->
              <input type="text" class="u-txt J-input-txt" placeholder="验证码" name="password" id="password"
                autocomplete="off" v-model="LoginUser.codes" style="width: 130px;border: 0;" />
              <div @click="xiuGetCode" style="align-items: center;justify-content: center;display: flex;cursor: pointer;color: #fff;width: 100px;
                            height: 30px;background: #0C6A6A;border-radius: 7px 7px 7px 7px;margin-right: 5px;">
                <div v-if="isClick">发送验证码</div>
                <div v-else>{{ second + 's后重新获取' }}</div>
              </div>
            </div>
            <div class="u-input mb20">
              <!-- <label class="u-label u-name"></label> -->
              <input type="password" class="u-txt J-input-txt" value="" placeholder="密码" autocomplete="off"
                v-model="LoginUser.pass" maxlength="11" style="width: 284px;" />
            </div>
            <div class="u-safe">
              <span class="auto">
                <label>
                  <input type="hidden" name="referurl" id="referurl" value="/Home/User/index.html" />
                  <input type="checkbox" class="u-ckb J-auto-rmb" name="remember"
                    style="visibility:hidden" /><!-- 自动登录 -->
                </label>
              </span>
              <br />
              <div class="line liney clearfix">
                <label class="linel">&nbsp;</label>
                <div class="liner">
                  <div class="clearfix checkcon">
                    <p class="fn-fl checktxt">
                      <input type="checkbox" class="iyes fn-fl J_protocal" checked v-model="checked" />
                      <span class="fn-fl">我已阅读并同意</span>
                      <router-link to="/helpInfo?id=3">《服务条款》</router-link>
                      <router-link to="/helpInfo?id=11">《隐私条款》</router-link>
                    </p>
                    <p class="fn-fl errorbox v-txt" id="protocalBox"></p>
                  </div>
                  <a id="submit" class="regbtn J_btn_agree" href="javascript:void(0);"
                    onClick="$('#reg_form2').submit();">同意协议并注册</a>
                  <p class="v-txt" id="err_check_code" v-if="false">
                    <span class="fnred">请勾选</span>我已阅读并同意<a class="itxt" :href="$target + '/服务协议.docx'"
                      target="_blank">《服务条款》</a>
                    <a class="itxt" :href="$target + '/隐私条款.docx'" target="_blank">《隐私条款》</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="u-btn mb20 mt20">
              <a href="javascript:void(0);" @click="xiugai()" class="J-login-submit" name="sbtbutton"
                style="background: #0C6A6A;">修改密码</a>
            </div>
            <div style="color: #0C6A6A;margin: 16px auto 0;text-align: center;cursor: pointer;" @click="zhuce = false,wangji = false">
              已有账户去登录</div>
          </form>
        </div>
        <div class="login-form" style="position: absolute;z-index: 1;right: 400px;" v-if="!zhuce && !wangji">
          <div class="title oh flexspb">
            <h1 class="fl">登录</h1>
            <div class="regist-link fr">
              <!-- <a href="/register">免费注册</a> -->
              <span style="color: #0C6A6A;cursor: pointer;" @click="zhuce = true">免费注册</span>
            </div>
          </div>
          <div class="u-msg-wrap">
            <!-- <div class="msg msg-warn" style="display:none;">
              <i></i>
              <span>公共场所不建议自动登录，以防帐号丢失</span>
            </div> -->
            <div class="msg msg-err" style="display:none;">
              <i></i>
              <span class="J-errorMsg"></span>
            </div>
          </div>
          <form id="login-form" method="post">
            <p class="co_ye"></p>
            <div class="u-input mb20">
              <!-- <label class="u-label u-name"></label> -->
              <input type="text" class="u-txt J-input-txt" value="" placeholder="手机号" name="username" id="username"
                autocomplete="off" v-model="LoginUser.name" style="width: 284px;" />
            </div>
            <div class="u-input mb15" style="border: 1px solid #ccc;display: flex;">
              <!-- <label class="u-label u-pwd"></label> -->
              <input type="password" class="u-txt J-input-txt" placeholder="密码" name="password" id="password"
                autocomplete="off" v-model="LoginUser.pass" style="border: 0;" />

            </div>
            <div class="u-safe">
              <span class="auto">
                <label>
                  <input type="hidden" name="referurl" id="referurl" value="/Home/User/index.html" />
                  <input type="checkbox" class="u-ckb J-auto-rmb" name="remember"
                    style="visibility:hidden" /><!-- 自动登录 -->
                </label>
              </span>
              <span class="forget" style="cursor: pointer;" @click="wangji = true">忘记密码？</span>
              <br />
              <div class="line liney clearfix">
                <label class="linel">&nbsp;</label>
                <div class="liner">
                  <div class="clearfix checkcon">
                    <p class="fn-fl checktxt">
                      <input type="checkbox" class="iyes fn-fl J_protocal" checked v-model="checked" />
                      <span class="fn-fl">我已阅读并同意</span>
                      <router-link to="/helpInfo?id=6">《服务条款》</router-link>
                      <router-link to="/helpInfo?id=5">《隐私条款》</router-link>
                    </p>
                    <p class="fn-fl errorbox v-txt" id="protocalBox"></p>
                  </div>
                  <a id="submit" class="regbtn J_btn_agree" href="javascript:void(0);"
                    onClick="$('#reg_form2').submit();">同意协议并注册</a>
                  <p class="v-txt" id="err_check_code" v-if="false">
                    <span class="fnred">请勾选</span>我已阅读并同意<a class="itxt" :href="$target + '/服务协议.docx'"
                      target="_blank">《服务条款》</a>
                    <a class="itxt" :href="$target + '/隐私条款.docx'" target="_blank">《隐私条款》</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="u-btn mb20 mt20">
              <a href="javascript:void(0);" @click="Login()" class="J-login-submit" name="sbtbutton"
                style="background: #0C6A6A;">登&nbsp;&nbsp;&nbsp;&nbsp;录</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { setToken } from "@/utils/auth";

export default {
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^1[3456789]\d{9}$/;
      if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("请填写正确手机号"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    return {
      checked: false,
      LoginUser: {
        name: "",
        pass: "",
        phone: '',
        codes: ''
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [
          {
            validator: validateName,
            trigger: "blur",
          },
        ],
        pass: [
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
      isClick: true,
      second: 60,
      Mobile: '',
      zhuce: false,
      wangji: false
    };
  },
  beforeCreate() { },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    async Login() {
      if (!this.checked) {
        this.notifyError("请勾选隐私条款");
        return false;
      }
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      // this.$refs["ruleForm"].validate(async (valid) => {
      //如果通过校验开始登录
      // if (valid) {
      var res = await this.postdata("/Login/WebLogin", {
        UserName: this.LoginUser.name,
        password: this.LoginUser.pass,
      });
      if (res.code == 200) {
        // 隐藏登录组件
        this.isLogin = false;
        // 登录信息存到本地
        localStorage.setItem("userToken", res.data);
        setToken(res.data);
        // 弹出通知框提示登录成功信息
        this.notifySucceed("登录成功");
        const res1 = await this.postdata("/User/GetUser");
        if (res1.code == 200) {
          this.setUser(res1.data);
          this.$router.replace('/')
        }
      } else {
        // 清空输入框的校验状态
        // this.$refs["ruleForm"].resetFields();
        // 弹出通知框提示登录失败信息
        this.notifyError(res.msg);
      }
      // } else {
      //   return false;
      // }
      // });
    },
    async getCode() {
      let that = this
      if (this.LoginUser.phone == '') {
        that.notifyError("请填写手机号");
        return
      }
      if (!RegExp(/^1[3456789]\d{9}$/).test(this.LoginUser.phone)) {
        that.notifyError("请填写正确的手机号");
        return
      }
      if (!this.isClick) {
        return
      }
      this.isClick = false
      var interval = setInterval(() => {
        let times = this.second - 1
        console.log(this.isClick)
        this.second = times < 10 ? '0' + times : times //小于10秒补 0

      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        this.second = 60
        this.isClick = true
      }, 60000)
      if (!this.isClick) {
        let data = await this.postdata("/Login/IsMobile", {
          "Mobile": this.LoginUser.phone //手机号		  
        })
        let inds = data.data ? 1 : 0
        if (data.data) {
          this.notifyError("该手机号已注册");
          return
        }
        var res = await this.postdata("/Login/SendCode", {
          "Mobile": this.LoginUser.phone, //手机号
          "SmsType": inds //0.注册  1.登录
        });
        if (res.code == 200) {
          this.notifySucceed(res.msg);
        } else {
          that.notifyError(res.msg);
          clearInterval(interval)
          this.second = 60
          this.isClick = true
        }
      }
    },
    async zhuces() {
      let that = this
      if (this.LoginUser.phone == '') {
        that.notifyError("请填写手机号");
        return
      }
      if (!RegExp(/^1[3456789]\d{9}$/).test(this.LoginUser.phone)) {
        that.notifyError("请填写正确的手机号");
        return
      }
      if (!this.checked) {
        this.notifyError("请勾选隐私条款");
        return false;
      }
      const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      if (!userNameRule.test(this.LoginUser.name)) {
        this.notifyError("用户名请设置字母开头,长度5-16之间,允许字母数字下划线");
        return false;
      }
      // const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      // if (!passwordRule.test(this.LoginUser.pass)) {
      //   this.notifyError("密码请设置字母开头,长度6-18之间,允许字母数字和下划线");
      //   return false;
      // }

      let res = await this.postdata('/Login/MobileLogin', {
        "mobile": this.LoginUser.phone, //手机号
        "smscode": this.LoginUser.codes, //短信验证码
        "UserName": this.LoginUser.name,
        "password": this.LoginUser.pass
      })
      if (res.code == 200) {
        this.notifySucceed(res.msg);
        this.zhuce = false
        this.LoginUser.phone = ''
        this.LoginUser.codes = ''
        this.LoginUser.name = ''
        this.LoginUser.pass = ''
      } else {
        that.notifyError(res.msg);
      }
    },
    async xiuGetCode() {
      let that = this
      if (this.LoginUser.phone == '') {
        that.notifyError("请填写手机号");
        return
      }
      if (!RegExp(/^1[3456789]\d{9}$/).test(this.LoginUser.phone)) {
        that.notifyError("请填写正确的手机号");
        return
      }
      if (!this.isClick) {
        return
      }
      this.isClick = false
      var interval = setInterval(() => {
        let times = this.second - 1
        console.log(this.isClick)
        this.second = times < 10 ? '0' + times : times //小于10秒补 0

      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        this.second = 60
        this.isClick = true
      }, 60000)
      if (!this.isClick) {
        let data = await this.postdata("/Login/IsMobile", {
          "Mobile": this.LoginUser.phone //手机号
        })
        let inds = data.data ? 1 : 0
        if (!data.data) {
          this.notifyError("该手机号未注册");
          return
        }
        var res = await this.postdata("/Login/SendCode", {
          "Mobile": this.LoginUser.phone, //手机号
          "SmsType": 2 //0.注册  1.登录
        });
        if (res.code == 200) {
          this.notifySucceed(res.msg);
        } else {
          that.notifyError(res.msg);
          clearInterval(interval)
          this.second = 60
          this.isClick = true
        }
      }
    },
    async xiugai() {
      this.LoginUser.name = ''
      let res = await this.postdata('/Login/MobileResetPassword', {
        "mobile": this.LoginUser.phone,
        "smscode": this.LoginUser.codes,
        "password": this.LoginUser.pass
      })
      if (res.code == 200) {
        this.notifySucceed(res.msg);
        this.wangji = false
        this.LoginUser.phone = ''
        this.LoginUser.codes = ''
        this.LoginUser.name = ''
        this.LoginUser.pass = ''
      } else {
        that.notifyError(res.msg);
      }
    }
  },
};
</script>
<style scoped>
.header {
  width: 1240px; 
  margin: 0 auto;
  background-color: #fff;
  padding-left: 60px;
}

.cents {
  width: 100%;
  /* background: #bf1919; */
}

.box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.m-login .login-form {
  float: right;
  width: 306px;
  padding: 20px;
  background-color: #fff;
  margin-top: 40px;
}

.m-login .login-form .title {
  height: 21px;
}

.m-login .login-form .title h1 {
  font-size: 16px;
  color: #666;
}

.m-login .login-form .title .regist-link a {
  font-size: 14px;
  color: #da3a4c;
}

.m-login .login-form .title .regist-link a:hover {
  text-decoration: underline;
}

.m-login .login-form .u-msg-wrap {
  padding: 5px 0 10px;
  font-family: "Microsoft YaHei";
}

.m-login .login-form .u-msg-wrap .msg {
  height: 24px;
  line-height: 24px;
  padding: 0 10px 0 30px;
  position: relative;
  color: #da3a4c;
}

.m-login .login-form .u-msg-wrap .msg.msg-warn {
  border: 1px solid #ffe9b1;
  background: #fff4d8;
}

/* .m-login .login-form .u-msg-wrap .msg.msg-warn i {
  background: url(../images/fn-icon.png) no-repeat 0 0;
} */
.m-login .login-form .u-msg-wrap .msg.msg-err {
  border: 1px solid #ffbdbe;
  background: #ffebeb;
  display: none;
}

.m-login .login-form .u-msg-wrap .msg.msg-err i {
  background: url(../images/dialog-login.png) no-repeat 0 -40px;
}

.m-login .login-form .u-msg-wrap .msg i {
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -7px;
}

.m-login .login-form .u-input {
  position: relative;
  margin-bottom: 12px;
}

.m-login .login-form .u-input .u-txt {
  width: 246px;
  height: 18px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
}

.m-login .login-form .u-input .u-txt.u-txt-err {
  border-color: #da3a4c;
}

.m-login .login-form .u-input .u-txt.u-txt-focus {
  border-color: #999;
}

.m-login .login-form .u-input .u-label {
  width: 41px;
  height: 36px;
  border-right: 1px solid #eee;
  position: absolute;
  left: 1px;
  top: 1px;
}

.m-login .login-form .u-input .u-name {
  background: url(../images/dialog-login.png) no-repeat 0 0;
}

.m-login .login-form .u-input .u-pwd {
  background: url(../images/dialog-login.png) no-repeat -39px 0;
}

.m-login .login-form .u-safe {
  position: relative;
  margin: 12px 0;
}

.m-login .login-form .u-safe .auto label {
  color: #666;
  cursor: pointer;
}

.m-login .login-form .u-safe .u-ckb {
  margin-right: 10px;
  vertical-align: middle;
}

.m-login .login-form .u-safe .forget {
  position: absolute;
  right: 0;
  top: 0;
}

.m-login .login-form .u-safe .forget a {
  color: #666;
}

.m-login .login-form .u-safe .forget a:hover {
  text-decoration: underline;
}

.m-login .login-form .u-btn a {
  display: block;
  width: 305px;
  height: 40px;
  line-height: 40px;
  border: none;
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-family: "Microsoft YaHei";
  background-color: #bf1919;
  cursor: pointer;
  text-decoration: none;
}

/* .m-login .login-form .u-btn a:hover {
  background-color: #cf0c22;
} */
.m-login .login-form .u-authcode {
  position: relative;
}

.m-login .login-form .u-authcode .u-txt {
  width: 88px;
  padding: 10px 10px;
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
}

.m-login .login-form .u-authcode .u-txt.u-txt-err {
  border-color: #da3a4c;
}

.m-login .login-form .u-authcode .u-txt.u-txt-focus {
  border-color: #999;
}

.m-login .login-form .u-authcode .code-img {
  width: 66px;
  height: 40px;
  vertical-align: middle;
}

.m-login .login-form .u-authcode a {
  color: #2176dd;
}

.m-login .login-form .u-authcode a:hover {
  text-decoration: underline;
}

.m-login .login-form .account {
  height: 30px;
  line-height: 30px;
  color: #999;
  word-spacing: -3px;
}

.m-login .login-form .account dt,
.m-login .login-form .account dd {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: top;
}

.m-login .login-form .account dd a {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 6px;
}

.m-login .login-form .account .qq:hover {
  background-position: -30px 0;
}

#login-form .co_ye {
  color: #ea5e00;
  margin-left: 6px;
  vertical-align: middle;
}

.fnlogin .line {
  height: 37px;
  padding-bottom: 25px;
}

.fnlogin .liney {
  height: auto;
  padding-bottom: 0px;
}

.fnlogin .line .dt {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
  /* background:url(../images/fn-icon.png) 0px -137px no-repeat; */
}

.fnlogin .line .linel {
  float: left;
  text-align: right;
  width: 90px;
}

.fnlogin .line .liner {
  float: left;
}

.show-voice {
  width: 700px;
  line-height: 20px;
  font-size: 12px;
  color: #999;
  padding-left: 85px;
  float: left;
  display: none;
}

.fnlogin .v-txt,
.regcon .ui_panel,
.fnlogin .v-txt2 {
  display: none;
}

.fnlogin .safeline {
  clear: both;
  padding-top: 4px;
  padding-left: 49px;
  color: #666;
}

.fnlogin .safeline .sz {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  color: #fff;
  background: #d5d5d5;
  width: 65px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  margin-left: 1px;
}

.fnlogin .safeline .son {
  background: #da3a4c;
}

.fnlogin .iyes {
  margin-right: 5px;
  margin-top: 2px;
}

.fnlogin .checkcon {
  height: 34px;
}

.fnlogin .checkcon .checktxt {
  height: 18px;
  padding-right: 106px;
  padding-top: 10px;
}

.fnlogin .itxt {
  color: #0066cc;
  text-decoration: none;
}

.fnlogin .itxt:hover {
  text-decoration: underline;
}

.fnlogin .regbtn {
  display: block;
  clear: both;
  cursor: pointer;
  margin: 20px 0px 10px 0;
  width: 250px;
  height: 40px;
  color: #fff;
  font-family: "Microsoft YaHei";
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  background: #da3a4c;
  text-decoration: none;
}

.fnlogin .regbtn:hover {
  background: #ce0c22;
}
</style>
